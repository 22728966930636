@import "css/partials/bootstrap/bootstrap.min.css";
@import "css/partials/animate.css";
@import "css/partials/font-awesome.css";
@import "css/partials/owl.carousel.css";
@import "../bower_components/jquery-ui/themes/base/jquery-ui.css";
@import "../bower_components/swipebox/src/css/swipebox.css";
@import "../bower_components/photoswipe/dist/photoswipe.css";
@import "../bower_components/photoswipe/dist/default-skin/default-skin.css";
@font-face {
    font-family: "gotham";
    src: url("fonts/GothamProBlack.eot#iefix") format("embedded-opentype"),
         url("fonts/GothamProBlack.woff") format("woff"),
         url("fonts/GothamProBlack.ttf") format("truetype");
    font-style: normal;
    font-weight: 900;
}
@font-face {
    font-family: "gotham";
    src: url("fonts/GothamProRegular.eot#iefix") format("embedded-opentype"),
         url("fonts/GothamProRegular.woff") format("woff"),
         url("fonts/GothamProRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
@font-face {
    font-family: "gotham";
    src: url("fonts/GothamProBold.eot#iefix") format("embedded-opentype"),
         url("fonts/GothamProBold.woff") format("woff"),
         url("fonts/GothamProBold.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
}
@font-face {
    font-family: "gotham";
    src: url("fonts/GothamProLight.eot#iefix") format("embedded-opentype"),
        url("fonts/GothamProLight.woff") format("woff"),
        url("fonts/GothamProLight.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
}
@font-face {
  font-family: "GothamProMedium";
  src: url("fonts/GothamProMedium/GothamProMedium.eot");
  src: url("fonts/GothamProMedium/GothamProMedium.eot?#iefix")format("embedded-opentype"),
       url("fonts/GothamProMedium/GothamProMedium.woff") format("woff"),
       url("fonts/GothamProMedium/GothamProMedium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
    font-family: 'Museo Sans Cyrl';
    src: url('fonts/MuseoSansCyrl-100Italic.woff2') format('woff2'),
        url('fonts/MuseoSansCyrl-100Italic.woff') format('woff'),
        url('fonts/MuseoSansCyrl-100Italic.ttf') format('truetype'),
        url('fonts/MuseoSansCyrl-100Italic.svg#MuseoSansCyrl-100Italic') format('svg');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans Cyrl';
    src: url('fonts/MuseoSansCyrl-300.woff2') format('woff2'),
        url('fonts/MuseoSansCyrl-300.woff') format('woff'),
        url('fonts/MuseoSansCyrl-300.ttf') format('truetype'),
        url('fonts/MuseoSansCyrl-300.svg#MuseoSansCyrl-300') format('svg');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Museo Sans Cyrl';
    src: url('fonts/MuseoSansCyrl-300Italic.woff2') format('woff2'),
        url('fonts/MuseoSansCyrl-300Italic.woff') format('woff'),
        url('fonts/MuseoSansCyrl-300Italic.ttf') format('truetype'),
        url('fonts/MuseoSansCyrl-300Italic.svg#MuseoSansCyrl-300Italic') format('svg');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Museo Sans Cyrl';
    src: url('fonts/MuseoSansCyrl-500Italic.woff2') format('woff2'),
        url('fonts/MuseoSansCyrl-500Italic.woff') format('woff'),
        url('fonts/MuseoSansCyrl-500Italic.ttf') format('truetype'),
        url('fonts/MuseoSansCyrl-500Italic.svg#MuseoSansCyrl-500Italic') format('svg');
    font-weight:  500;
    font-style: italic;
}
@font-face {
    font-family: 'Museo Sans Cyrl';
    src: url('fonts/MuseoSansCyrl-500.woff2') format('woff2'),
        url('fonts/MuseoSansCyrl-500.woff') format('woff'),
        url('fonts/MuseoSansCyrl-500.ttf') format('truetype'),
        url('fonts/MuseoSansCyrl-500.svg#MuseoSansCyrl-500') format('svg');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Museo Sans Cyrl';
    src: url('fonts/MuseoSansCyrl-700Italic.woff2') format('woff2'),
        url('fonts/MuseoSansCyrl-700Italic.woff') format('woff'),
        url('fonts/MuseoSansCyrl-700Italic.ttf') format('truetype'),
        url('fonts/MuseoSansCyrl-700Italic.svg#MuseoSansCyrl-700Italic') format('svg');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Museo Sans Cyrl';
    src: url('fonts/MuseoSansCyrl-700.woff2') format('woff2'),
        url('fonts/MuseoSansCyrl-700.woff') format('woff'),
        url('fonts/MuseoSansCyrl-700.ttf') format('truetype'),
        url('fonts/MuseoSansCyrl-700.svg#MuseoSansCyrl-700') format('svg');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Museo Sans Cyrl';
    src: url('fonts/MuseoSansCyrl-900Italic.woff2') format('woff2'),
        url('fonts/MuseoSansCyrl-900Italic.woff') format('woff'),
        url('fonts/MuseoSansCyrl-900Italic.ttf') format('truetype'),
        url('fonts/MuseoSansCyrl-900Italic.svg#MuseoSansCyrl-900Italic') format('svg');
    font-weight:  900;
    font-style: italic;
}
@font-face {
    font-family: 'Museo Sans Cyrl';
    src: url('fonts/MuseoSansCyrl-900.woff2') format('woff2'),
        url('fonts/MuseoSansCyrl-900.woff') format('woff'),
        url('fonts/MuseoSansCyrl-900.ttf') format('truetype'),
        url('fonts/MuseoSansCyrl-900.svg#MuseoSansCyrl-900') format('svg');
    font-weight: 900;
    font-style: normal;
}
body {
    font-family: 'Museo Sans Cyrl';
    font-weight: 300;
    color: #282828;
    letter-spacing: 0.5px;
}
#swipebox-overlay {
    background: rgba(0,0,0,0.5);
}
.clear {
    display: table;
    clear: both;
}
input[name="desc"] {
    position: fixed;
    top: -99999999999999em;
    opacity: 0;
    width: 0;
    height: 0;
}
.link-icon {
    display: block;
    &.icon-phone {
        width: 30px;
        height: 30px;
        background-color: #d8dcdf;
        -webkit-mask:  url(img/icons/telephone-handle-silhouette.svg) no-repeat 50% 50%;
        mask: url(img/icons/telephone-handle-silhouette.svg) no-repeat 50% 50%;
        -webkit-mask-size: cover;
        mask-size: cover;
    }
    &.icon-chat {
        width: 30px;
        height: 30px;
        /*background: url(img/icons/chat.png);
        background-size: contain;*/
        background-color: #d8dcdf;
        -webkit-mask:  url(img/icons/chat.svg) no-repeat 50% 50%;
        mask: url(img/icons/chat.svg) no-repeat 50% 50%;
        -webkit-mask-size: cover;
        mask-size: cover;
    }
    &.icon-whatsapp {
        width: 30px;
        height: 30px;
        background: url(img/icons/whatsapp.svg);
        background-size: contain;
        /*background: url(img/icons/whatsapp-icon.png);
        background-size: contain;*/
        /*background-color: #d8dcdf;
        -webkit-mask:  url(img/icons/chat.svg) no-repeat 50% 50%;
        mask: url(img/icons/chat.svg) no-repeat 50% 50%;*/
        /*-webkit-mask-size: cover;*/
        /*mask-size: cover;*/
    }
    &.icon-insta {
        width: 30px;
        height: 30px;
        background: url(img/icons/insta.svg);
        background-size: contain;
        /*background: url(img/icons/insta-icon.png);
        background-size: contain;*/
        /*background-color: #d8dcdf;
        -webkit-mask:  url(img/icons/chat.svg) no-repeat 50% 50%;
        mask: url(img/icons/chat.svg) no-repeat 50% 50%;*/
        /*-webkit-mask-size: cover;*/
        /*mask-size: cover;*/
    }
}
.btn {
    &.btn-blue {
        background: #4375f9;
        color: #fff;
        padding: 13px 30px;
        font-size: 16px;
        font-weight: 300;
    }
}
video::-webkit-media-controls {
  display: none;
}
.table {
    display: table;
    table-layout: fixed;
    width: 100%;
    .table-cell {
        display: table-cell;
        vertical-align: middle;
        &.cell-bottom {
            vertical-align: bottom;
        }
    }
    &.table-absolute {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
    }
}
.top-panel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    &.bottom-panel {
        position: relative;
        background: #2851bf;
        padding-bottom: 34px;
    }
    .top-panel-items {
        padding-top: 34px;
        &:before {
            content: '';
            display: table;
            clear: both;
        }
        .top-panel-item {
            float: right;
            padding: 0 15px 0 15px;
            &.logo-item {
                float: left;
                padding-left: 0;
                .top-panel-logo {
                    color: #fff;
                    font-size: 16px;
                    padding-top: 4px;
                    padding-bottom: 3px;
                }
            }
            &.chat-icon-item {
                padding-right: 0;
            }
            &.phone-item {
                position: relative;
                padding-right: 30px;
                margin-right: 15px;
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    right: 0;
                    width: 0;
                    top: 0;
                    bottom: 0;
                    border-right: 1px solid #fff;
                }
                a {
                    color: #fff;
                    text-decoration: none;
                    font-size: 16px;
                    display: block;
                    padding-top: 4px;
                    padding-bottom: 3px;
                }
            }
        }
    }
}
.screen {
    position: relative;
    &.top-screen {
        height: 100vh;
    }
    &.kompleks-screen {
        padding-top: 96px;
        padding-bottom: 96px;
    }
}
.video-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    video {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        min-height: 100%;
        min-width: 100%;
        width: 100%;
    }
    .mob-image {
        position: absolute;
        background-image: url(img/mob-miamy.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
.top-screen {
    color: #fff;
    .top-text {
        font-size: 104px;
        line-height: normal;
        font-weight: 900;
        padding-bottom: 140px;
        border-bottom: 1px solid #fff;
        padding-left: 0;
        padding-right: 0;
        .big-title {
            span {
                position: relative;
                &:after {
                  content: '';
                  display: inline-block;
                  position: absolute;
                  right: -16px;
                  top: 20px;
                  width: 1px;
                  /*height: 14px;*/
                  bottom: 15px;
                  background: #fff;
                  opacity: 1;
                  z-index: 3;
                  animation: cursor 1s infinite step-end;
                }
            }
        }
    }
    .top-screen-bottom-block {
        padding-top: 50px;
        padding-bottom: 90px;
        font-size: 24px;
        line-height: normal;
        .top-screen-bottom-left {
            padding-left: 0;
            .text-big {
                font-size: 46px;
                font-weight: bold;
            }
        }
        .top-screen-bottom-right {
            padding-top: 10px;
            padding-right: 0;
            .right-block {
                display: inline-block;
            }
        }
    }
}
@keyframes cursor {
  50% { background: transparent; }
}
.kompleks-screen {
    .komplex-items {
        .komplex-item {
            padding-left: 30px;
            padding-right: 30px;
            &:nth-child(1) {
                padding-left: 15px;
                padding-right: 45px;
            }
            &:nth-child(3) {
                padding-right: 15px;
                padding-left: 45px;
            }
            .komplex-image {
                width: 100%;
                padding-top: 118%;
                background-size: cover;
            }
            .komplex-title {
                font-size: 30px;
                font-weight: 900;
                padding-top: 36px;
                padding-bottom: 36px;
            }
        }
    }
}
.footer-section {
    background: #4375f9;
    .footer-row {
        padding-top: 50px;
        padding-bottom: 0;
        &.no-padding {
            padding-top: 0;
            padding-bottom: 54px;
        }
        .form-bottom-text {
            font-size: 12px;
            color: #fff;
            text-align: left;
            display: inline-block;
            padding-top: 20px;
            a {
                color: #fff;
                text-decoration: underline;
            }
        }
        .footer-block-item {
            color: #fff;
            .footer-text {
                font-size: 30px;
                font-weight: 900;
                line-height: normal;
                padding-top: 70px;
            }
            .form-block {
                .form-group {
                    margin: 0;
                }
                .label {
                    font-size: 16px;
                    padding-top: 56px;
                    padding-bottom: 22px;
                }
                .checkboxes {
                    display: inline-block;
                    margin-right: 24px;
                    input {
                        display: none;
                    }
                    label {
                        width: 40px;
                        height: 40px;
                        display: block;
                        border-radius: 50%;
                        border: 1px solid #fff;
                        background: transparent;
                        text-align: center;
                        padding-top: 8px;
                        color: #fff;
                        cursor: pointer;
                        margin: 0;
                        -webkit-transition: all 0.3s ease-in-out;
                        -moz-transition: all 0.3s ease-in-out;
                        -o-transition: all 0.3s ease-in-out;
                        transition: all 0.3s ease-in-out;
                    }
                    input:checked+label {
                        background: #fff;
                        color: #000;
                    }
                }
                .form-input {
                    .form-control {
                        max-width: 87%;
                        height: 40px;
                    }
                    &.line-input {
                        &:before , &:after {
                            content: '';
                            display: table;
                            clear: both;
                        }
                        .form-control {
                            float: left;
                            max-width: 38%;
                        }
                        .arrow-control {
                            float: left;
                            width: 6%;
                            padding-top: 18px;
                            margin-left: 2.5%;
                            margin-right: 2.5%;
                            min-height: 1px;
                            border-bottom: 1px solid #fff;
                        }
                    }
                    .btn {
                        background: #ffa716;
                        display: block;
                        width: 100%;
                        color: #fff;
                        max-width: 87%;
                        height: 50px;
                        margin-top: -5px;
                        margin-bottom: -5px;
                    }
                }
            }
        }
    }
}
.laptop-br {
    display: none;
}
@media all and (min-width: 800px) and (max-width: 1600px) {
    .laptop-br {
        display: block;
    }
    .top-panel .top-panel-items .top-panel-item.logo-item .top-panel-logo {
        /*display: none;*/
    }
    .top-panel.bottom-panel .top-panel-items .top-panel-item.logo-item .top-panel-logo {
        display: block;
    }
    .top-screen .top-text {
        font-size: 72px;
    }
    .top-screen .top-screen-bottom-block {
        padding-bottom: 40px;
        padding-top: 40px;
        font-size: 16px;
    }
    .top-screen .top-screen-bottom-block .top-screen-bottom-left .text-big {
        font-size: 34px;
    }
    .kompleks-screen .komplex-items .komplex-item .komplex-title {
        font-size: 22px;
    }
    .screen.kompleks-screen {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    .kompleks-screen .komplex-items .komplex-item {
        font-size: 14px;
    }
    .footer-section .footer-row .footer-block-item .footer-text {
        font-size: 16px;
    }
    .lap-top-hidden {
        display: none;
    }
    .footer-section .footer-row .footer-block-item {
        -ms-flex: 0 0 22.2%;
        -webkit-box-flex: 0;
        flex: 0 0 22.2%;
        max-width: 22.2%;
        &.footer-item-item-text {
            -ms-flex: 0 0 16.666667%;
            -webkit-box-flex: 0;
            flex: 0 0 16.666667%;
            max-width: 16.666667%;
        }
        &.padding-item {
            -ms-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
            max-width: 8.333333%;
        }
    }
    .footer-section .footer-row .form-bottom-text {
        padding-right: 60px;
        br {
            display: none;
            &.laptop-br {
                display: block;
            }
        }
    }
    .footer-section .footer-row .footer-block-item .form-block .form-input .btn , 
    .footer-section .footer-row .footer-block-item .form-block .form-input .form-control {
        max-width: 100%;
    }
    .footer-section .footer-row .footer-block-item .form-block .checkboxes {
        margin-right: 20px;
    }
}
.visible-xs {
    display: none;
}
.hidden-xs {
    display: block;
}
@media all and (max-width: 800px) {
    .visible-xs {
        display: block;
    }
    .hidden-xs {
        display: none !important;
    }
    .screen.top-screen {
        height: 100%;
        min-height: 480px;
        .table.table-absolute {
            top: auto;
            bottom: 0;
            margin-bottom: 0;
        }
    }
    .top-screen .top-text {
        font-size: 30px;
        margin-left: 15px;
        margin-right: 15px;
        padding-bottom: 80px;
    }
    .top-panel .top-panel-items .top-panel-item.phone-item {
        display: none;
    }
    .top-screen .top-screen-bottom-block .top-screen-bottom-left .text-big {
        font-size: 24px;
    }
    .top-screen .top-screen-bottom-block {
        font-size: 12px;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .top-screen .top-screen-bottom-block .top-screen-bottom-right {
        display: none;
    }
    .kompleks-screen .komplex-items .komplex-item .komplex-title {
        font-size: 18px;
    }
    .kompleks-screen .komplex-items .komplex-item:nth-child(1),
    .kompleks-screen .komplex-items .komplex-item:nth-child(2),
    .kompleks-screen .komplex-items .komplex-item:nth-child(3) {
        padding-left: 15px;
        padding-right: 15px;
    }
    .screen.kompleks-screen {
        padding-top: 0;
        padding-bottom: 36px;
    }
    .footer-section .footer-row {
        padding-top: 0;
    }
    .footer-section .footer-row .footer-block-item.footer-item-item-text {
        background: #fff;
        color: #000;
        padding-bottom: 30px;
    }
    .footer-section .footer-row .footer-block-item .footer-text {
        padding-top: 0;
        font-size: 16px;
    }
    .footer-section .footer-row .footer-block-item .footer-line {
        /*text-align: center;*/
        padding-left: 25px;
        padding-right: 25px;
    }
    .footer-section .footer-row .footer-block-item .form-block {
        /*display: inline-block;
        margin: 0 auto;
        text-align: left;*/
    }
    .footer-section .footer-row .footer-block-item .form-block .checkboxes-list {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .footer-section .footer-row .footer-block-item .form-block .checkboxes {
        /*-ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;*/
        margin-right: 0;
    }
    .footer-section .footer-row .footer-block-item .form-block .checkboxes label {
        margin-right: 0;
    }
    .footer-section .footer-row .footer-block-item .form-block .label {
        padding-top: 30px;
    }
    .footer-section .footer-row .footer-block-item .form-block .form-input .form-control ,
    .footer-section .footer-row .footer-block-item .form-block .form-input .btn {
        max-width: 100%;
    }
    .footer-section .footer-row .footer-block-item .form-block .form-input.line-input .form-control {
        float: left;
        max-width: 44.5%;
    }
    .footer-section .footer-row .form-bottom-text {
        padding-left: 25px;
        padding-right: 25px;
        display: block;
        width: 100%;
    }
    .top-panel.bottom-panel .top-panel-items .top-panel-item.logo-item .top-panel-logo {
        font-size: 10px;
    }
}

